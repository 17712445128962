import PropTypes from 'prop-types';
import React from 'react';

import { connect } from 'react-redux';

class Check extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
        };

        this.parent = React.createRef();
    }

    render() {
        const { user } = this.props;

        return (
            <>
                <div ref={this.parent} className="form">
                    <div className="form__head">
                        <div className="form__headTitle">Данные успешно загружены</div>
                    </div>
                    <div className="form__info">
                        <div className="form__infoText">
                            <b>
                                Благодарим за предоставление данных, проверка займёт до 5 рабочих
                                дней.
                            </b>
                        </div>
                        <div className="form__infoText">
                            Если данные верные, то мы направим подарок на вашу почту{' '}
                            <span>{user?.personal?.email}</span>.
                        </div>
                        <div className="form__infoText">
                            Если в данных обнаружена ошибка, мы пришлём уведомление на вашу почту{' '}
                            <span>{user?.personal?.email}</span>. Их надо будет поправить по ссылке,
                            направленной в письме.
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(Check);

Check.propTypes = {
    user: PropTypes.object,
};
