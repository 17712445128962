import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import FullAnket from './steps/FullAnket.jsx';
import Loader from '../components/Loader.jsx';
import Error from './steps/Error.jsx';
import Check from './steps/Check.jsx';
import Akt from './steps/Akt.jsx';
import Accept from './steps/Accept.jsx';

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    steps = {
        error: Error,
        akt: Akt,
        anket: FullAnket,
        check: Check,
        accept: Accept,
    };

    getStep() {
        const { user, authIsError } = this.props;

        if (!user || authIsError) {
            return 'error';
        }

        if (user.status === 'DATA_ACCEPTED') {
            return 'accept';
        }

        if (user.status === 'ACT_REQUIRED') {
            return 'akt';
        }

        if (user.status === 'EXTRA_ANKET_REQUIRED') {
            return 'anket';
        }

        if (user.status === 'PARTICIPANT') {
            return 'check';
        }

        return 'error';
    }

    componentDidMount() {
        this.step = this.getStep();
    }

    componentDidUpdate() {
        if (this.step !== this.getStep()) {
            this.step = this.getStep();

            document.querySelector('html').scrollTop = 0;
        }
    }

    render() {
        const { user, authIsError } = this.props;

        const Step = this.steps[this.getStep()];

        return (
            <div ref={this.parent} className="index">
                <img src={require('../media/logo.svg').default} alt="" className="index__logo" />
                <div className="index__inner">
                    <div className="index__head" />
                    <div className="index__content">
                        {user || authIsError ? (
                            <>
                                <Step />
                            </>
                        ) : (
                            <>
                                <div className="index__loader">
                                    <div className="index__loaderItem">
                                        <Loader />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="index__foot">
                    <div className="index__footTitle">
                        Если остались вопросы, пишите на почту Акции{' '}
                        <a href="#">plus_daily@thediversity.ru</a>
                    </div>
                    <div className="index__footText">
                        Срок совершения предусмотренных Правилами действий в рамках Акции с целью
                        участия в розыгрыше призов составляет период с 5 ноября 2024 г. по 24 ноября
                        2024 г. (включительно). Срок вручения призов до 31.12.2024. Информация об
                        организаторе Акции, условиях участия, правилах проведения, призах, их
                        количестве, сроках, месте и порядке их получения узнавайте в Правилах
                        размещенных по адресу в сети
                        Интернет https://yandex.ru/legal/dailyplusbelka © 2024, ООО «Дайверсити».
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        authIsError: state.authIsError,
    };
}

export default connect(mapStateToProps)(Index);

Index.propTypes = {
    user: PropTypes.object,
    authIsError: PropTypes.bool,
};
