import React from 'react';

import { connect } from 'react-redux';

class Error extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
        };

        this.parent = React.createRef();
    }

    render() {
        return (
            <>
                <div ref={this.parent} className="form">
                    <div className="form__head">
                        <div className="form__headTitle">Неверная ссылка</div>
                    </div>
                    <div className="form__info">
                        <div className="form__infoText">Если у вас возникли вопросы:</div>
                        <div className="form__infoText">plus_daily@thediversity.ru</div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(Error);
